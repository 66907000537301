import Vue from 'vue';
import PageBase from '@glittr/frontend-core/src/core/v2/app/pageBase';
import { GoogleMapsApi } from '@glittr/frontend-core/src/plugins/map/mapProvider/googleMapsProvider';
import DataSource from '@glittr/frontend-core/src/core/v2/data/data-source';
import Tdg_CompanyDetailModel from '../../../../services/v2/model/tdg-company-detail-model';

export interface MyTourElement {
  Id: number,
  CompanyName: string,
  CompanyLogo?: string,
  CompanyJobs?: string,
  CountApprenticeships?: number,
  CompanyDescription?: string,
  CompanyStreet: string,
  CompanyHouseNumber: string,
  CompanyCity: string,
  CompanyPostalCode: string,
  CompanyContact: string,
  CompanyPhone: string,
  CompanyEmail: string,
  CompanyWebsite?: string,
}

export default class TdgMapPage extends PageBase {
  googleApi?: GoogleMapsApi;

  map?: google.maps.Map;

  showInfoDiv = false;

  showCompanyList = true;

  mapContainerElement = {} as HTMLElement;

  markers: google.maps.marker.AdvancedMarkerElement[] = [];

  tourElement: Tdg_CompanyDetailModel | null = null;

  selectedCompanyId: number | undefined = undefined;

  get myTourListItems() {
    return Vue.$sessionStorage.get<MyTourElement[]>('myTourElements') ?? [];
  }

  set removeMyTourListItems(value: MyTourElement[]) {
    Vue.$sessionStorage.set('myTourElements', value);
  }

  public companiesDataSource = new DataSource({
    selectCommand: Vue.$service.v2.api.tdg_Companies.getCompanyDetailById,
  });

  mapItemDataSource = new DataSource({
    selectCommand: Vue.$service.v2.api.tdg_Maps.getMapItems,
  });

  async initialize(): Promise<void> {
    this.googleApi = await Vue.$map.google.initialize('core', 'maps', 'marker', 'places');
  }

  async initializeTdgMap() {
    this.map = new this.googleApi!.maps.Map(
      this.mapContainerElement,
      {
        mapTypeId: this.googleApi!.maps.MapTypeId.SATELLITE,
        disableDefaultUI: false,
        zoom: 18,
        center: { lat: 47.1174765953623, lng: 9.149508327579966 },
        mapId: (Vue.$config.values as any)['google-mapsmapid'],
      },
    );
  }

  async loadMarkerItems() {
    await this.mapItemDataSource.select();
    const mapItems = this.mapItemDataSource.data?.items;
    mapItems?.forEach((i) => {
      const marker = new this.googleApi!.marker.AdvancedMarkerElement({
        position: new this.googleApi!.core.LatLng(i.data.latitude!, i.data.longitude!),
        map: this.map,
        title: i.data.title,
      });
      marker.addListener('click', () => {
        if (i.data.id !== undefined) {
          this.showInfoBox(i.data.id);
        } else {
          Vue.$log.error('Firmendetails ID ist undefined.');
        }
      });
      this.markers.push(marker);
    });
  }

  addToMyTour(Id: number | undefined, element: Tdg_CompanyDetailModel) {
    if (Id === undefined) {
      Vue.$log.error('Cannot add to tour, Id is undefined.');
      return;
    }

    const myTourElements: MyTourElement[] = Vue.$sessionStorage.get<MyTourElement[]>('myTourElements') ?? [];
    const existingElement = myTourElements.find((item) => item.Id === Id);
    if (existingElement) {
      Vue.$log.error('Diese Firma ist bereits in der Liste.');
      return;
    }

    const newTourElement = {
      Id,
      CompanyName: element.companyName,
      CompanyLogo: element.companyLogo,
      CompanyJobs: element.companyJobs,
      CountApprenticeships: element.companyApprenticeshipsCount,
      CompanyDescription: element.companyDescription,
      CompanyStreet: element.companyAddress?.street,
      CompanyHouseNumber: element.companyAddress?.houseNumber,
      CompanyCity: element.companyAddress?.city,
      CompanyPostalCode: element.companyAddress?.postalCode,
      CompanyContact: element.companyContactDetails?.contactName,
      CompanyPhone: element.companyContactDetails?.phoneNumber,
      CompanyEmail: element.companyContactDetails?.emailAddress,
      CompanyWebsite: element.companyContactDetails?.website,
    } as MyTourElement;
    myTourElements.push(newTourElement);
    Vue.$sessionStorage.set('myTourElements', myTourElements);
    this.showCompanyList = false; // Liste temporär verstecken
    Vue.nextTick(() => {
      this.showCompanyList = true; // Liste wieder anzeigen
    });
  }

  removeItem(Id: number) {
    const myTourElements: MyTourElement[] = Vue.$sessionStorage.get<MyTourElement[]>('myTourElements') ?? [];
    const updatedList = myTourElements.filter((item) => item.Id !== Id);
    Vue.$sessionStorage.set('myTourElements', updatedList);
    this.showCompanyList = false; // Liste temporär verstecken
    Vue.nextTick(() => {
      this.showCompanyList = true; // Liste wieder anzeigen
    });
  }

  public async showInfoBox(companyId: number) {
    this.showInfoDiv = true;
    this.selectedCompanyId = companyId;
    try {
      this.companiesDataSource.filter.id = companyId;
      await this.companiesDataSource.select();

      this.tourElement = new Tdg_CompanyDetailModel({
        companyName: this.companiesDataSource.data?.data.companyName,
        companyAddress: this.companiesDataSource.data?.data.companyAddress,
        companyApprenticeshipsCount: this.companiesDataSource.data?.data.companyApprenticeshipsCount,
        companyContactDetails: this.companiesDataSource.data?.data.companyContactDetails,
        companyDescription: this.companiesDataSource.data?.data.companyDescription,
        companyJobs: this.companiesDataSource.data?.data.companyJobs,
        companyLogo: null,
        organisationContactDetails: this.companiesDataSource.data?.data.organisationContactDetails,
        organisationLogo: null,
        organisationName: this.companiesDataSource.data?.data.organisationName,
      });
    } catch (error) {
      Vue.$log.error(`Fehler beim Abrufen der Firmendetails: ${error}`);
    }
  }

  public hideInfoBox() {
    this.showInfoDiv = false;
  }

  public addCompanyToList() {
    Vue.$log.info('Firma wurde der Liste hinzugefügt');
    Vue.$sessionStorage.set('myTour', 'meineFirma');
  }

  public getFormattedCompanyJobs(): string[] {
    return this.companiesDataSource.data?.data.companyJobs ? this.companiesDataSource.data?.data.companyJobs.split('\n').map((job) => job.trim()) : [];
  }

  public toggleSideDrawer() {
    if (this.showCompanyList === true) {
      this.showCompanyList = false;
    } else if (this.showCompanyList === false) {
      this.showCompanyList = true;
    }
  }
}
