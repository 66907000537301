<template>
  <div class="registrationWrapper company">
    <p-form v-if="!page.registrationSuccess" :model="page.combinedModels" class="registrationForm form">
      <div class="registrierungsCodeWrapper">
        <p-text-field v-model="page.combinedModels.company.accessToken" name="accessToken" :label="$t('page.createCompany.label.externalId')" required></p-text-field>
      </div>
      <div class="firmenDetailsWrapper">
        <h3 class="title">
          {{ $t('page.createCompany.title.companyAdress') }}
        </h3>
        <p-text-field v-model="page.combinedModels.company.companyName" :label="$t('page.createCompany.label.companyName')" required></p-text-field>
        <p-row class="streetWrapper">
          <p-col class="street">
            <p-text-field v-model="page.combinedModels.company.companyStreet" :label="$t('page.createCompany.label.companyStreet')" required></p-text-field>
          </p-col>
          <p-col class="houseNumber">
            <p-text-field v-model="page.combinedModels.company.companyHouseNumber" :label="$t('page.createCompany.label.companyHouseNumber')"></p-text-field>
          </p-col>
        </p-row>
        <p-row class="cityWrapper">
          <p-col class="zip">
            <p-text-field v-model="page.combinedModels.company.companyZIP" :label="$t('page.createCompany.label.companyZIP')" required></p-text-field>
          </p-col>
          <p-col class="city">
            <p-text-field v-model="page.combinedModels.company.companyCity" :label="$t('page.createCompany.label.companyCity')" required></p-text-field><br>
          </p-col>
        </p-row>
        <p-file-drop
          v-model="page.logoDocuments"
          class="logo file image"
          :label="$t('page.createCompany.label.LogoDocumentId')"
          max="7"
          accept="image/*"
          single
        ></p-file-drop>
        <p-text-field v-model="page.combinedModels.company.website" :label="$t('page.createCompany.label.website')"></p-text-field>
        <p-textarea v-model="page.combinedModels.company.description" :label="$t('page.createCompany.label.description')"></p-textarea>
        <p-number-field v-model="page.combinedModels.company.employeesCount" :label="$t('page.createCompany.label.employeesCount')"></p-number-field>
        <p-number-field v-model="page.combinedModels.company.apprenticesCount" :label="$t('page.createCompany.label.apprenticesCount')"></p-number-field>
        <p-number-field v-model="page.combinedModels.company.apprenticeShipsCount" :label="$t('page.createCompany.label.apprenticeShipsCount')"></p-number-field>
        <p-textarea v-model="page.combinedModels.company.jobs" :messages="$t('page.createCompany.label.jobs.message')" :label="$t('page.createCompany.label.jobs')"></p-textarea>
        <p-location
          :default-latitude="47.1174765953623"
          :default-longitude="9.149508327579966"
          @latitudeChange="page.combinedModels.company.companyLat = $event"
          @longitudeChange="page.combinedModels.company.companyLong = $event"
        ></p-location>
      </div>
      <div class="kontaktDetailsWrapper">
        <h3 class="title">
          {{ $t('page.createCompany.titel.companyContactDetails') }}
        </h3>
        <p-select v-model="page.combinedModels.company.companyContactSalutation" :label="$t('page.createCompany.label.companyContactSalutation')" :items="page.genderOptions()" required></p-select>
        <p-text-field v-model="page.combinedModels.company.companyContactFirstName" :label="$t('page.createCompany.label.companyContactFirstName')" required></p-text-field>
        <p-text-field v-model="page.combinedModels.company.companyContactLastName" :label="$t('page.createCompany.label.companyContactLastName')" required></p-text-field>
        <p-phone-field v-model="page.combinedModels.company.companyContactPhoneNumber" :label="$t('page.createCompany.label.companyContactPhoneNumber')" required></p-phone-field>
        <p-text-field v-model="page.combinedModels.company.companyContactEmail" :label="$t('page.createCompany.label.companyContactEmail')" required type="email"></p-text-field>
      </div>
      <div class="loginWrapper">
        <h3 class="title">
          {{ $t('page.widget.title.createNewUser') }}
        </h3>
        <p-text-field v-model="page.combinedModels.user.email" :placeholder="page.combinedModels.company.companyContactEmail" :label="$t('page.widget.label.userEmail')" required></p-text-field>
        <p-text-field v-model="page.combinedModels.user.password" :label="$t('page.widget.label.userPassword')" required type="password"></p-text-field>
        <p-text-field v-model="page.combinedModels.user.repeatPassword" :label="$t('page.widget.label.userSecondPassword')" type="password" required></p-text-field>
      </div>
      <div class="checkboxenWrapper">
        <p-checkbox v-model="page.combinedModels.company.acceptConditions" :label="$tEnum('page.createCompany.label.acceptConditions', 'no_html')" required>
          <span v-html="$tEnum('page.createCompany.label.acceptConditions', 'html')"></span>
        </p-checkbox>
      </div>
    </p-form>
    <p-row v-if="!page.registrationSuccess" class="saveButton">
      <p-button :disabled="page.isLoading" :loading="page.isLoading" @click="page.saveNewCompanies()">
        {{ $t('core.app.save') }}
      </p-button>
    </p-row>
    <p-request-alert v-if="!page.registrationSuccess" v-model="page.error"></p-request-alert>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import CompanyRegistrationPage from './companyRegistration.vue.model';

  export default Vue.extend({
    name: 'CompanyRegistration',
    layout: 'layout-widget',
    data: () => ({
      page: new CompanyRegistrationPage(),
    }),
    beforeMount() {
      const urlParams = new URLSearchParams(window.location.search);
      const accessToken = urlParams.get('accessToken');
      if (accessToken) {
        this.page.combinedModels.company.accessToken = accessToken;
      }
    },
    mounted() {
      this.page.initialize();
    },
  });
</script>
