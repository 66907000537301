<template>
  <div class="tdgMapWrapper">
    <div id="tdgMap" ref="tdgMap" class="map"></div>
    <div class="overlayWrapper" :class="{ show: page.showInfoDiv }">
      <div class="overlay"></div>
      <p-overlay class="infoBoxWrapper">
        <p-request-alert v-model="page.companiesDataSource.error"></p-request-alert>
        <div v-if="page.companiesDataSource.data">
          <div class="companyDetailsWrapper">
            <h3 class="title">
              {{ page.companiesDataSource.data.data.companyName }}
            </h3>
            <p-img v-if="page.companiesDataSource.data.data.companyLogo" :src="`data:image/jpeg;base64,${page.companiesDataSource.data.data.companyLogo}`" alt="Logo"></p-img>
            <p v-if="page.getFormattedCompanyJobs().length > 0" class="subtitle">
              {{ $t('page.map.overlay.jobs') }}:
            </p>
            <p v-if="page.getFormattedCompanyJobs().length > 0" class="jobsWrapper">
              <ul class="jobsList">
                <li v-for="(job, index) in page.getFormattedCompanyJobs()" :key="index">
                  {{ job }}
                </li>
              </ul>
            </p>
            <p v-if="page.companiesDataSource.data.data.companyApprenticeshipsCount" class="subtitle apprenticeships">
              {{ $t('page.map.overlay.apprenticeshipsData') }}: {{ page.companiesDataSource.data.data.companyApprenticeshipsCount }}
            </p>
            <div v-if="page.companiesDataSource.data.data.companyDescription">
              <p class="subtitle description">
                {{ $t('page.map.overlay.companyDescription') }}:
              </p>
              <p>{{ page.companiesDataSource.data.data.companyDescription }}</p>
            </div>
          </div>
          <div v-if="page.companiesDataSource.data.data.companyAddress" class="companyAdressWrapper">
            <p class="subtitle">
              {{ $t('page.map.overlay.companyAdress') }}
            </p>
            <p>{{ page.companiesDataSource.data.data.companyAddress.street }} {{ page.companiesDataSource.data.data.companyAddress.houseNumber }}</p>
            <p>{{ page.companiesDataSource.data.data.companyAddress.postalCode }} {{ page.companiesDataSource.data.data.companyAddress.city }}</p>
          </div>
          <div v-if="page.companiesDataSource.data.data.companyContactDetails" class="companyContactWrapper">
            <p class="subtitle">
              {{ $t('page.map.overlay.companyContact') }}
            </p>
            <p>{{ page.companiesDataSource.data.data.companyContactDetails.contactName }}</p>
            <p>
              <a :href="`tel:${page.companiesDataSource.data.data.companyContactDetails.phoneNumber}`">
                {{ page.companiesDataSource.data.data.companyContactDetails.phoneNumber }}
              </a>
            </p>
            <p>
              <a :href="`mailto:${page.companiesDataSource.data.data.companyContactDetails.emailAddress}`">{{ page.companiesDataSource.data.data.companyContactDetails.emailAddress }}</a>
            </p>
            <p v-if="page.companiesDataSource.data.data.companyContactDetails.website">
              <a :href="getFullUrl(page.companiesDataSource.data.data.companyContactDetails.website)" target="_blank" rel="noopener noreferrer">
                {{ page.companiesDataSource.data.data.companyContactDetails.website }}
              </a>
            </p>
          </div>
          <div v-if="page.companiesDataSource.data.data.organisationContactDetails" class="organisationWrapper">
            <p class="subtitle">
              {{ $t('page.map.overlay.belongsTo') }}:
            </p>
            <p>{{ page.companiesDataSource.data.data.organisationName }}</p>
            <p-img v-if="page.companiesDataSource.data.data.organisationLogo" :src="`data:image/jpeg;base64,${page.companiesDataSource.data.data.organisationLogo}`" alt="Logo"></p-img>
            <p>{{ page.companiesDataSource.data.data.organisationContactDetails.contactName }}</p>
            <p>
              <a :href="`tel:${page.companiesDataSource.data.data.organisationContactDetails.phoneNumber}`">
                {{ page.companiesDataSource.data.data.organisationContactDetails.phoneNumber }}
              </a>
            </p>
            <p>
              <a :href="`mailto:${page.companiesDataSource.data.data.organisationContactDetails.emailAddress}`">{{ page.companiesDataSource.data.data.organisationContactDetails.emailAddress }}</a>
            </p>
          </div>
          <div class="buttonWrapper">
            <p-button @click="page.hideInfoBox()">
              {{ $t('page.map.button.hideInfoBox') }}
            </p-button>
            <p-button @click="page.tourElement ? page.addToMyTour(page.selectedCompanyId, page.tourElement) : null">
              {{ $t('page.map.button.addToMyTour') }}
            </p-button>
          </div>
        </div>
      </p-overlay>
    </div>
    <div class="myTourSideDrawerWrapper" :class="{ extended: page.showCompanyList }">
      <div class="myTourSideDrawerToggle">
        <p-button @click="page.toggleSideDrawer()">
          <p-icon>{{ page.showCompanyList ? 'arrow_forward_ios' : 'arrow_back_ios' }}</p-icon>
        </p-button>
      </div>
      <div class="myTourSideDrawerContent">
        <h3 class="title">
          {{ $t('page.map.list.title') }}
        </h3>
        <div class="companyListWrapper">
          <p-list-group>
            <p-list>
              <p-list-entry v-for="element in page.myTourListItems" :key="element.Id">
                <p-container class="listEntryContainer">
                  <div class="companyInfoWrapper">
                    <div class="listCompanyDetailsWrapper">
                      <p> {{ element.CompanyName }} </p>
                      <p v-if="element.CompanyJobs" class="subtitle">
                        {{ $t('page.map.overlay.jobs') }}:
                      </p>
                      <div v-if="element.CompanyJobs" class="jobsWrapper">
                        <ul class="unorderedJobsList">
                          <li v-for="job in element.CompanyJobs.split('\n')" :key="job" class="jobsList">
                            {{ job }}
                          </li>
                        </ul>
                      </div>
                      <p v-if="element.CountApprenticeships">
                        {{ $t('page.map.overlay.apprenticeshipsData') }}: {{ element.CountApprenticeships }}
                      </p>
                    </div>
                    <div class="listCompanyAdressWrapper">
                      <p>{{ element.CompanyStreet }} {{ element.CompanyHouseNumber }}</p>
                      <p>{{ element.CompanyPostalCode }} {{ element.CompanyCity }}</p>
                    </div>
                    <div class="listCompanyContactWrapper">
                      <p>{{ element.CompanyContact }}</p>
                      <p>
                        <a :href="`tel:${element.CompanyPhone}`">
                          {{ element.CompanyPhone }}
                        </a>
                      </p>
                      <p>
                        <a :href="`mailto:${element.CompanyEmail}`">{{ element.CompanyEmail }}</a>
                      </p>
                      <p v-if="element.CompanyWebsite">
                        <a :href="getFullUrl(element.CompanyWebsite)" target="_blank" rel="noopener noreferrer">
                          {{ element.CompanyWebsite }}
                        </a>
                      </p>
                    </div>
                  </div>
                  <p-button class="deleteButton" @click="page.removeItem(element.Id)">
                    {{ $t('page.map.button.deleteButton') }}
                  </p-button>
                </p-container>
              </p-list-entry>
            </p-list>
          </p-list-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import TdgMapPage from './tdgMap.vue.model';

  export default Vue.extend({
    name: 'TdgMap',
    layout: 'layout-widget',
    data: () => ({
      page: new TdgMapPage(),
    }),
    async mounted() {
      await this.page.initialize();
      this.page.mapContainerElement = this.$refs.tdgMap as HTMLElement;
      await this.page.initializeTdgMap();
      await this.page.loadMarkerItems();
    },
    methods: {
      getFullUrl(url: string) {
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
          return `http://${url}`;
        }
        return url;
      },
    },
  });
</script>
