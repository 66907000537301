import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import tdg_users from './_generated/api/tdg-users';
import Tdg_UserModel from './model/tdg-user-model';
import GetMyRegistrationTypeRequestModel from './model/get-my-registration-type-request-model';

const tdgUsersCache: Map<number, Tdg_UserModel> = new Map();

async function getTdgUserAndCache(service: Servicelayer): Promise<Tdg_UserModel | undefined> {
  const currentUser = await Vue.$auth.getUser();
  if (!currentUser?.id) {
    return undefined;
  }
  let user: Tdg_UserModel | undefined;
  if (!tdgUsersCache.has(currentUser.id)) {
    Vue.$log.debug(`Added User with id '${currentUser.id}' to registration type cache`);
    const queriedUser = (await service.v2.api.tdg_Users.getMyRegistrationType(new GetMyRegistrationTypeRequestModel())).data;
    tdgUsersCache.set(currentUser.id, queriedUser);
    user = queriedUser;
  } else {
    Vue.$log.debug(`User with id '${currentUser.id}' was already cached in registration type cache`);
    user = tdgUsersCache.get(currentUser.id);
  }
  return user;
}

export default (service: Servicelayer) => ({
  ...tdg_users(service),

  async amIOrganisation(): Promise<boolean> {
    const user = await getTdgUserAndCache(service);
    return user?.registrationType === 'Organisation';
  },

  async amICompany(): Promise<boolean> {
    const user = await getTdgUserAndCache(service);
    return user?.registrationType === 'Company';
  },
});
