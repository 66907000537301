import PageBase from '@glittr/frontend-core/src/core/v2/app/pageBase';
import DataSource from '@glittr/frontend-core/src/core/v2/data/data-source';
import Vue from 'vue';
import Tdg_OrganisationModel from '../../../../services/v2/model/tdg-organisation-model';
import personalDetailsVueSchema from './editOrganisations.vue.schema';
import UploadTdgDocumentRequestModel from '../../../../services/v2/model/upload-tdg-document-request-model';

export default class EditOrganisationsPage extends PageBase {
  public get companiesList() {
    return this.companiesDataSource.data?.items.map((i) => { return { ...i.data }; }) || [];
  }

  public set companiesList(value) { /* Do nothing */ }

  public get organisation() { return this.organisationsDataSource?.data?.data ?? new Tdg_OrganisationModel(); }

  public error?: Error | string = undefined;

  public organisationId: number | undefined = undefined;

  public adminMode: boolean = false;

  public logoDocument: undefined | File[];

  public genderOptions() {
    return [
      { id: 0, caption: Vue.$t('core.address.salutationEnum3') },
      { id: 1, caption: Vue.$t('core.address.salutationEnum1') },
      { id: 2, caption: Vue.$t('core.address.salutationEnum2') },
    ];
  }

  public companyColumns = [
    { value: Vue.$t('page.companies.table.id'), textAlign: 'left', field: 'id' },
    { value: Vue.$t('page.companies.table.companyName'), textAlign: 'left', field: 'companyName' },
    { value: Vue.$t('page.companies.table.organisationName'), textAlign: 'left', field: 'organisationName' },
    { value: Vue.$t('page.companies.table.companyContactFirstName'), textAlign: 'left', field: 'companyContactFirstName' },
    { value: Vue.$t('page.companies.table.companyContactLastName'), textAlign: 'left', field: 'companyContactLastName' },
    { value: Vue.$t('page.companies.table.companyContactPhoneNumber'), textAlign: 'left', field: 'companyContactPhoneNumber' },
    { value: Vue.$t('page.companies.table.companyContactEmail'), textAlign: 'left', field: 'companyContactEmail' },
    { value: '', field: 'actions' },
  ];

  public organisationsDataSourceAdmin = new DataSource({
    selectCommand: Vue.$service.v2.api.tdg_Organisations.getOrganisationById,
  });

  public organisationsDataSourceMy = new DataSource({
    selectCommand: Vue.$service.v2.api.tdg_Organisations.getMyOrganisation,
  });

  public companiesDataSource = new DataSource({
    selectCommand: Vue.$service.v2.api.tdg_Companies.getCompanies,
  });

  private organisationsDataSource?: typeof this.organisationsDataSourceAdmin | typeof this.organisationsDataSourceMy = undefined;

  async initialize(): Promise<void> {
    this.companiesDataSource.filter.selectedOrganisationId = this.organisationId;
    if (this.adminMode) {
      this.organisationsDataSourceAdmin.filter.id = this.organisationId;
      this.organisationsDataSource = this.organisationsDataSourceAdmin;
    } else {
      this.organisationsDataSource = this.organisationsDataSourceMy;
    }
    await this.organisationsDataSource.select();
    await this.companiesDataSource.select();
  }

  public editOrganisations = async () => {
    const valid = this.organisation.validateWithSchema(personalDetailsVueSchema);
    if (valid) {
      const logoFiles = this.logoDocument ?? [];
      if (logoFiles.length > 0) {
        const request = new UploadTdgDocumentRequestModel();
        request.uploadFile = this.logoDocument?.[0];
        const documentId = await Vue.$service.v2.api.tdg_Documents.uploadTdgDocument(request);
        this.organisation.logoDocumentExternalId = documentId.data;
      } else {
        this.organisation.logoDocumentExternalId = undefined;
      }
      try {
        await this.organisationsDataSource?.update(this.organisation);
        Vue.$router.back();
      } catch (error: any) {
        Vue.$log.error(error);
        this.error = error;
      }
    }
  };

  async refreshCompanies() {
    try {
      await this.companiesDataSource.select();
    } catch (error) {
      Vue.$log.error(`Error refreshing companies: ${error}`);
    }
  }
}
